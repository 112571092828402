/* eslint-disable max-lines */
import { IUserSignUp } from 'redux/sagas/user/interfaces';

import * as actionTypes from './actionTypes';

import { IError, Tags } from 'services/Sentry/types';
import { errorType } from 'services/Sentry/Sentry';

import { IThankYouPage } from 'interfaces/Onboarding/onboarding';

export function signUp(data: IUserSignUp) {
    return {
        type: actionTypes.SIGN_UP,
        payload: data,
    } as const;
}

export function updateUserInfoOld(payload: IThankYouPage) {
    return {
        type: actionTypes.UPDATE_INFO_OLD,
        payload: payload,
    } as const;
}

export function updateUserInfoFail(error: Error) {
    return {
        type: actionTypes.UPDATE_INFO_FAIL,
        payload: error,
    } as const;
}

export function updateUserInfoSuccess() {
    return {
        type: actionTypes.UPDATE_INFO_SUCCESS,
    } as const;
}
export function setIsLogin(status: boolean) {
    return {
        type: actionTypes.SET_IS_LOGIN,
        payload: status,
    } as const;
}

export function setToken(token: string | null) {
    return {
        type: actionTypes.SET_USER_TOKEN,
        payload: token,
    } as const;
}

export function setUserEmail(email: string) {
    return {
        type: actionTypes.SET_EMAIL,
        payload: email,
    } as const;
}

export function setLanguage(language: string) {
    return {
        type: actionTypes.SET_LANGUAGE,
        payload: language,
    } as const;
}

export function setCountry(country: string) {
    return {
        type: actionTypes.SET_COUNTRY,
        payload: country,
    } as const;
}

export function setIsAutologinComplete(status: boolean) {
    return {
        type: actionTypes.SET_IS_AUTOLOGIN_COMPLETE,
        payload: status,
    } as const;
}

export function setPaidStatus(status: boolean) {
    return {
        type: actionTypes.SET_PAID_STATUS,
        payload: status,
    } as const;
}
export function signUpSuccess() {
    return {
        type: actionTypes.SIGN_UP_SUCCESS,
    } as const;
}

export function signUpFail() {
    return {
        type: actionTypes.SIGN_UP_FAIL,
    } as const;
}

export function setFullURL(url: string) {
    return {
        type: actionTypes.SET_FULL_URL,
        payload: url,
    } as const;
}

export function setUserName(name: string) {
    return {
        type: actionTypes.SET_USER_NAME,
        payload: name,
    } as const;
}

export function setUserSurname(surname: string) {
    return {
        type: actionTypes.SET_USER_SURNAME,
        payload: surname,
    } as const;
}

export function setIsInitTestania(status: boolean) {
    return {
        type: actionTypes.SET_IS_INIT_TESTANIA,
        payload: status,
    } as const;
}

export function setBranchForChallenge(branchForChallenge: string) {
    return {
        type: actionTypes.SET_BRANCH_FOR_CHALLENGE,
        payload: branchForChallenge,
    } as const;
}

export function handleErrorAction(error: unknown, logType: errorType, payload?: any, customTags?: Tags) {
    return {
        type: actionTypes.HANDLE_ERROR_ACTION,
        payload: { error: error as IError, data: payload, logType, customTags },
    } as const;
}

export function setIsOnlyAppUser(isOnlyAppUser: boolean) {
    return {
        type: actionTypes.SET_IS_ONLY_APP_USER,
        payload: isOnlyAppUser,
    } as const;
}

export function setUserProducts(products: Record<string, boolean>) {
    return {
        type: actionTypes.SET_USER_PRODUCTS,
        payload: products,
    };
}
