/* eslint-disable max-lines */
import { Provider } from 'wikr-core-analytics';
import Core from 'testania';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { selectBranchForChallenge, selectEmail, selectLanguage } from 'redux/User/selectors';
import { setTokenOperation } from 'redux/User/operations';
import { SIGN_UP } from 'redux/User/actionTypes';
import { setIsOnlyAppUser, signUp, signUpFail, signUpSuccess } from 'redux/User/actions';
import { selectIsErrorModalOpen } from 'redux/UiEffects/selectors';
import { showModal } from 'redux/UiEffects/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import {
    selectAge,
    selectCurrentBody,
    selectCurrentWeight,
    selectEquipmentPreset,
    selectFitnessLevel,
    selectGoal,
    selectIsSkipOB,
    selectMeasureSystem,
    selectMotivation,
    selectOccasion,
    selectOccasionDate,
    selectSetbacks,
    selectTall,
    selectTargetBody,
    selectTargetWeight,
    selectTargetZone,
    selectTrainingDaysGoal,
    selectTrainingDuration,
    selectTrainingLocations,
    selectURLParams,
} from 'redux/Onboadring/selectors';

import { DEFAULT_USER, LTV_COEFFICIENT_KEY } from 'constants/user';
import { CALISTHENICS_BRANCH, locations } from 'constants/onboarding/onboardingConstants';
import { defaultGender, defaultTestName } from 'constants/onboarding/defaultOnboardingData';
import { DEFAULT_EMAIL_ERROR_TEXT } from 'constants/errors/errors';

import sentry from 'services/Sentry/SentryInstance';
import { trackScreenLoad } from 'services/Analytics/trackers';

import { getBirthday, getBranchName } from 'helpers/onboarding/onboardingUtils';

import { TrainingDaysGoal } from 'types/pages/onboardingPages/commonTypes';
import {
    CurrentBodyType,
    EquipmentPresets,
    FitnessLevelType,
    GoalType,
    MeasuringSystemType,
    OccasionList,
    TargetBodyType,
    TargetZonesType,
    TrainingDuration,
    WorkoutLocationsType,
} from 'types/onboarding/onboarding';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';
import { ISingUpResponse, UserSingUpInfo } from 'interfaces/User/user';
import { ISignUpErrorResponse, IUserSignUp, userSignUpError } from './interfaces';
import { handleRequiredFields, sendClickAnalytic, sendSuccessfulSignUpAnalytic } from './helper';

export function* signUpSaga({ payload }: ReturnType<typeof signUp>) {
    try {
        const targetWeight: string = yield select(selectTargetWeight);
        const weight: string = yield select(selectCurrentWeight);
        const target_zones: Array<TargetZonesType> = yield select(selectTargetZone);
        const fitness_level: FitnessLevelType = yield select(selectFitnessLevel);
        const height: string = yield select(selectTall);
        const goal: GoalType = yield select(selectGoal);
        const age: number = yield select(selectAge);
        const language: string = yield select(selectLanguage);
        const training_locations: Array<WorkoutLocationsType> = yield select(selectTrainingLocations);
        const measureSystem: MeasuringSystemType = yield select(selectMeasureSystem);
        const email: string = yield select(selectEmail);
        const isErrorModalOpened: boolean = yield select(selectIsErrorModalOpen);
        const urlParams: Record<string, string> = yield select(selectURLParams);
        const target_bodytype: TargetBodyType = yield select(selectTargetBody);
        const current_bodytype: CurrentBodyType = yield select(selectCurrentBody);
        const testaniaName: string = yield select(selectTestaniaName);
        const branchForChallenge: string = yield select(selectBranchForChallenge);
        const isSkipOB: boolean = yield select(selectIsSkipOB);
        const date_of_birth: string = getBirthday(age);
        const branchName = getBranchName(urlParams, branchForChallenge);
        const equipment_preset: EquipmentPresets | null = yield select(selectEquipmentPreset);
        const training_days_goal: TrainingDaysGoal | null = yield select(selectTrainingDaysGoal);
        const training_duration_goal: TrainingDuration | null = yield select(selectTrainingDuration);
        const occasion_calendar: string | null = yield select(selectOccasionDate);
        const setbacks: string[] = yield select(selectSetbacks);
        const occasion: OccasionList = yield select(selectOccasion);
        const motivation: string[] = yield select(selectMotivation);

        if (branchName === CALISTHENICS_BRANCH) {
            DEFAULT_USER.training_locations = [locations.home];
        }

        const meta: UserSingUpInfo = {
            email,
            consent_marketing: isSkipOB,
            date_of_birth,
            fitness_level,
            goal,
            problem_zones: target_zones,
            gender: defaultGender,
            training_locations: training_locations ?? DEFAULT_USER.training_locations,
            units: measureSystem.toLowerCase(),
            height: parseFloat(height),
            language,
            target_weight: parseFloat(targetWeight),
            weight: parseFloat(weight),
            branch_name: branchName,
            test_name: urlParams?.['test-name'] || defaultTestName,
            ab_test_name: testaniaName,
            target_bodytype,
            current_bodytype,
            setbacks,
            motivation,
            occasion,
            ...(occasion_calendar && { occasion_calendar }),

            ...(equipment_preset && { equipment_preset }),
            ...(training_days_goal && { training_days_goal }),
            ...(training_duration_goal && { training_duration_goal }),
        };

        sendClickAnalytic(payload?.options?.customURL);

        handleRequiredFields(meta, DEFAULT_USER);

        const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const finalDataForSignUp: UserSingUpInfo = { ...DEFAULT_USER, ...meta };

        const response: ISingUpResponse = yield api.user.signUp({ ...finalDataForSignUp, timezone });

        const token: string = response?.headers?.token;

        if (!token) {
            yield put(signUpFail());
            // @ts-ignore
            yield call(setErrorMessage, DEFAULT_EMAIL_ERROR_TEXT, payload.setErrors);
        }

        if (!response.error && !isErrorModalOpened && token) {
            const id: number = response.data.id;

            setTokenOperation(token);

            sentry.setUser({ email, user_id: id });
            Provider.setUserId(id).then();
            Provider.setUserEmail(email);

            sendSuccessfulSignUpAnalytic();

            yield put(signUpSuccess());

            yield call(payload.toNextPage, true);

            const { weight, height, gender } = response.data;

            // @ts-ignore
            Core.calculateLTVCoefficient({
                age: Number(age),
                weight,
                height,
                gender,
                utm_source: urlParams?.utm_source || 'other',
            })
                .then((coefficient: string) => {
                    localStorage.setItem(LTV_COEFFICIENT_KEY, coefficient);
                })
                .catch((error: Error) => {
                    sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.ERROR);
                });
        }
    } catch (error) {
        yield put(signUpFail());
        yield call(handleErrorStatuses, error, payload);
        sentryCaptureEvent(error, SENTRY_APP, ERROR_LEVELS.ERROR, {});
    }
}

export function* handleErrorStatuses(error: ISignUpErrorResponse, payload: IUserSignUp) {
    const errorInfo: userSignUpError = {};

    errorInfo.status = error.response?.status;

    const isWebUserExist = errorInfo.status === 409;
    const isOnlyAppUser = errorInfo.status === 400;
    const eventId = isOnlyAppUser ? 'mobile_' : '';

    if (isWebUserExist || isOnlyAppUser) {
        yield put(setIsOnlyAppUser(isOnlyAppUser));

        yield put(showModal(true));
        yield call(payload.resetForm);

        trackScreenLoad(`${eventId}user_existed`, { event_label: 'pop_up' });
    }

    // @ts-ignore
    yield call(setErrorMessage, DEFAULT_EMAIL_ERROR_TEXT, payload.setErrors);
}

export function* setErrorMessage(message: string, setErrors: (error: { email: string }) => void) {
    const error = {
        email: message,
    };

    yield call(setErrors, error);
}

export const signUpUser = function* () {
    yield takeLatest(SIGN_UP, signUpSaga);
};
